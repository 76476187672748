const APPMODE = "DCA";

let serviceCollection;
let FARM_ALIAS;
let MODE;
let OPS_DATA_URL;
let MONITOR_DATA_URL;
let userPoolId;
let userPoolWebClientId;
let region;
let mainPageTitle;
let portalPageTitle;
let farmGeoData;
let farmDetails;

if (APPMODE === "ESM") {
  const SMAX_ARTICLES = { code: "SMAX_ARTICLES", name: "smax articles" };
  const SMAX_NEW_REQUEST = { code: "SMAX_NEW_REQUEST", name: "smax create new request" };
  const SMAX_ESS_ARTICLE = { code: "SMAX_ESS_ARTICLE", name: "smax ess article" };
  const SMAX_ESS_OFFERING = { code: "SMAX_ESS_OFFERING", name: "smax ess offering" };
  const SMAX_LIVE_SUPPORT = { code: "SMAX_LIVE_SUPPORT", name: "smax live support" };
  const SMAX_LOGIN = { code: "SMAX_LOGIN", name: "smax login" };
  const SMAX_OPEN_EDIT_REQUEST = { code: "SMAX_OPEN_EDIT_REQUEST", name: "smax open and edit the request" };
  const SMAX_SEARCH = { code: "SMAX_SEARCH", name: "smax search" };
  const SMAX_SACM = { code: "SMAX_SACM", name: "smax sacm" };
  const SMAX_CMS_ADMIN_CONSOLE_LOGIN = { code: "SMAX_CMS_ADMIN_CONSOLE_LOGIN", name: "cms admin console login" };
  const SMAX_CMS_FIND_ENTIT = { code: "SMAX_CMS_FIND_ENTIT", name: "cms find entity" };

  serviceCollection = [
    [
      SMAX_ARTICLES,
      SMAX_NEW_REQUEST,
      SMAX_ESS_ARTICLE,
      SMAX_ESS_OFFERING,
      SMAX_LIVE_SUPPORT,
      SMAX_LOGIN,
      SMAX_OPEN_EDIT_REQUEST,
      SMAX_SEARCH,
      SMAX_SACM,
      SMAX_CMS_ADMIN_CONSOLE_LOGIN,
      SMAX_CMS_FIND_ENTIT,
    ],
    [
      SMAX_ARTICLES,
      SMAX_NEW_REQUEST,
      SMAX_ESS_ARTICLE,
      SMAX_ESS_OFFERING,
      SMAX_LIVE_SUPPORT,
      SMAX_LOGIN,
      SMAX_OPEN_EDIT_REQUEST,
      SMAX_SEARCH,
      SMAX_SACM,
    ]
  ];

  FARM_ALIAS = ['us2', 'us24', 'us6', 'us7', 'eu3', 'eu8', 'eu18', 'ap10', 'jp12', 'br14', 'ca16'];
  MODE = ['ESM', 'SMAX', 'HCMX', 'AMX'];

  // // dev url
  // // export const OPS_DATA_URL = "http://localhost:3000";
  // OPS_DATA_URL = "https://kjncjstlqa.execute-api.us-west-2.amazonaws.com/dev";
  // MONITOR_DATA_URL = "https://adubp7zqfa.execute-api.us-west-2.amazonaws.com/dev";
  // // dev cognito
  // userPoolId = "us-west-2_vGWkIsXER"
  // userPoolWebClientId = "24p150bfnlmoc5tchoblg4e1pk";

  // production url
  OPS_DATA_URL = "https://bj3ostqudl.execute-api.us-west-2.amazonaws.com/prod";
  MONITOR_DATA_URL = "https://ruljjxvrda.execute-api.us-west-2.amazonaws.com/prod";
  // production cognito
  userPoolId = "us-west-2_hdQ8PQGg6";
  userPoolWebClientId = "61cb6mn9eulgfmg35c7g6g9u6k";

  region = "us-west-2";
  mainPageTitle = "Enterprise Service Management System Health";
  portalPageTitle = "System Health of Service Management Automation";

  farmGeoData = {
    "features": [
      {
        "type": "Feature",
        "properties": {
          "title": "ca16",
          "description": "Central farm"
        },
        "geometry": {
          "coordinates": [-79, 50],
          "type": "Point"
        }
      },
      {
        "type": "Feature",
        "properties": {
          "title": "eu8",
          "description": "Frankfurt farm"
        },
        "geometry": {
          "coordinates": [8.34, 50],
          "type": "Point"
        }
      },
      {
        "type": "Feature",
        "properties": {
          "title": "eu18",
          "description": "Frankfurt farm"
        },
        "geometry": {
          "coordinates": [8.34, 50],
          "type": "Point"
        }
      },
      {
        "type": "Feature",
        "properties": {
          "title": "eu3",
          "description": "Frankfurt farm"
        },
        "geometry": {
          "coordinates": [8.34, 50],
          "type": "Point"
        }
      },
      {
        "type": "Feature",
        "properties": {
          "title": "us2",
          "description": "Oregon farm"
        },
        "geometry": {
          "coordinates": [-122.39, 45.31],
          "type": "Point"
        }
      },
      {
        "type": "Feature",
        "properties": {
          "title": "us24",
          "description": "Oregon farm"
        },
        "geometry": {
          "coordinates": [-122.39, 45.31],
          "type": "Point"
        }
      },
      {
        "type": "Feature",
        "properties": {
          "title": "us6",
          "description": "Oregon farm"
        },
        "geometry": {
          "coordinates": [-122.39, 45.31],
          "type": "Point"
        }
      },
      {
        "type": "Feature",
        "properties": {
          "title": "us7",
          "description": "Oregon farm"
        },
        "geometry": {
          "coordinates": [-122.39, 45.31],
          "type": "Point"
        }
      },
      {
        "type": "Feature",
        "properties": {
          "title": "jp12",
          "description": "Tokyo farm"
        },
        "geometry": {
          "coordinates": [139.44, 35.41],
          "type": "Point"
        }
      },
      {
        "type": "Feature",
        "properties": {
          "title": "ap10",
          "description": "Sydney farm"
        },
        "geometry": {
          "coordinates": [151.12, -33.41],
          "type": "Point"
        }
      },
      {
        "type": "Feature",
        "properties": {
          "title": "br14",
          "description": "São Paulo farm"
        },
        "geometry": {
          "coordinates": [-46.6334, -23.5507],
          "type": "Point"
        }
      }

    ],
    "type": "FeatureCollection"
  }

  farmDetails = [
    {
      "name": "us2",
      "location": "Oregon",
      "version": "2022.1",
      "url": "https://smax-health.saas.microfocus.com/dashboard/?farm=us2"
    },
    {
      "name": "us24",
      "location": "Oregon",
      "version": "2022.1",
      "url": "https://smax-health.saas.microfocus.com/dashboard/?farm=us24"
    },
    {
      "name": "us6",
      "location": "Oregon",
      "version": "2022.1",
      "url": "https://smax-health.saas.microfocus.com/dashboard/?farm=us6"
    },
    {
      "name": "us7",
      "location": "Oregon",
      "version": "2022.1",
      "url": "https://smax-health.saas.microfocus.com/dashboard/?farm=us7"
    },
    {
      "name": "eu3",
      "location": "Frankfurt",
      "version": "2022.1",
      "url": "https://smax-health.saas.microfocus.com/dashboard/?farm=eu3"
    },
    {
      "name": "eu8",
      "location": "Frankfurt",
      "version": "2022.1",
      "url": "https://smax-health.saas.microfocus.com/dashboard/?farm=eu8"
    },
    {
      "name": "eu18",
      "location": "Frankfurt",
      "version": "2022.1",
      "url": "https://smax-health.saas.microfocus.com/dashboard/?farm=eu18"
    },
    {
      "name": "ap10",
      "location": "Sydney",
      "version": "2022.1",
      "url": "https://smax-health.saas.microfocus.com/dashboard/?farm=ap10"
    },
    {
      "name": "jp12",
      "location": "Tokyo",
      "version": "2022.1",
      "url": "https://smax-health.saas.microfocus.com/dashboard/?farm=jp12"
    },
    {
      "name": "br14",
      "location": "São Paulo",
      "version": "2022.1",
      "url": "https://smax-health.saas.microfocus.com/dashboard/?farm=br14"
    },
    {
      "name": "ca16",
      "location": "Central",
      "version": "2022.1",
      "url": "https://smax-health.saas.microfocus.com/dashboard/?farm=ca16"
    }
  ]


}
else if (APPMODE === "DCA") {
  const DCA_BENCHMARKS = { code: "DCA_BENCHMARKS", name: "DCA BENCHMARKS" };
  const DCA_JOBS_PAGE = { code: "DCA_JOBS_PAGE", name: "DCA JOBS PAGE" };
  const DCA_PATCHBUNDLES = { code: "DCA_PATCHBUNDLES", name: "DCA PATCHBUNDLES" };
  const DCA_RESOURCES_PAGE = { code: "DCA_RESOURCES_PAGE", name: "DCA RESOURCES PAG" };
  const DCA_ACCESS_REPORT = { code: "DCA_ACCESS_REPORT", name: "DCA ACCESS REPORT" };
  const DCA_LOGIN_DCA = { code: "DCA_LOGIN_DCA", name: "DCA LOGIN" };
  const DCA_LOGOUT_DCA = { code: "DCA_LOGOUT_DCA", name: "DCA LOGOUT" };
  const DCA_LOGIN_BVD = { code: "DCA_LOGIN_BVD", name: "BVD LOGIN" };
  const DCA_LOGOUT_BVD = { code: "DCA_LOGOUT_BVD", name: "BVD LOGOUT" };

  serviceCollection = [
    [
      DCA_BENCHMARKS,
      DCA_JOBS_PAGE,
      DCA_PATCHBUNDLES,
      DCA_RESOURCES_PAGE,
      DCA_ACCESS_REPORT,
      DCA_LOGIN_DCA,
      DCA_LOGOUT_DCA,
      DCA_LOGIN_BVD,
      DCA_LOGOUT_BVD
    ]
  ];

  FARM_ALIAS = [
    'eu2-prod-dca',
    'eu3-trial-dca',
    'eu4-stg-dca',
    'ap5-trial-dca',
    'us6-prod-reporting',
    'us8-stg-reporting'
  ];
  MODE = ['DCA'];

  // staging url
  // export const AWS_EVENT_URL = "http://localhost:3000";
  OPS_DATA_URL = "https://s9w43cz52b.execute-api.eu-central-1.amazonaws.com/stg";
  MONITOR_DATA_URL = "https://dydxgwepec.execute-api.eu-central-1.amazonaws.com/stg";
  // staging cognito
  userPoolId = "eu-central-1_hEhCfKwCA";
  userPoolWebClientId = "7pjonkgerkct7m0k0hokh3279g";

  // // production url
  // OPS_DATA_URL = "https://j6t90w1d62.execute-api.eu-central-1.amazonaws.com/prod";
  // MONITOR_DATA_URL = "https://cqkxh9zi4a.execute-api.eu-central-1.amazonaws.com/prod";
  // // production cognito
  // userPoolId = "eu-central-1_MX3qMTuu2";
  // userPoolWebClientId = "47notflvt0gk2jg7niilgm370t";

  region = "eu-central-1";
  mainPageTitle = "DCA"
  portalPageTitle = "DCA";
  farmGeoData = {
    "features": [
      {
        "type": "Feature",
        "properties": {
          "title": "eu2-prod-dca",
          "description": "Frankfurt Farm"
        },
        "geometry": {
          "coordinates": [8.34, 50],
          "type": "Point"
        }
      },
      {
        "type": "Feature",
        "properties": {
          "title": "eu3-trial-dca",
          "description": "Frankfurt Farm"
        },
        "geometry": {
          "coordinates": [8.34, 50],
          "type": "Point"
        }
      },
      {
        "type": "Feature",
        "properties": {
          "title": "eu4-stg-dca",
          "description": "Frankfurt Farm"
        },
        "geometry": {
          "coordinates": [8.34, 50],
          "type": "Point"
        }
      },
      {
        "type": "Feature",
        "properties": {
          "title": "ap5-trial-dca",
          "description": "Sydney farm"
        },
        "geometry": {
          "coordinates": [151.12, -33.41],
          "type": "Point"
        }
      },
      {
        "type": "Feature",
        "properties": {
          "title": "us6-prod-reporting",
          "description": "Oregon farm"
        },
        "geometry": {
          "coordinates": [-122.39, 45.31],
          "type": "Point"
        }
      },
      {
        "type": "Feature",
        "properties": {
          "title": "us8-stg-reporting",
          "description": "Oregon farm"
        },
        "geometry": {
          "coordinates": [-122.39, 45.31],
          "type": "Point"
        }
      }
    ],
    "type": "FeatureCollection"
  }

  farmDetails = [
    {
      "name": "eu2-prod-dca",
      "location": "Frankfurt",
      "version": "2022.1",
      "url": "https://dca-health.saas.microfocus.com/dashboard/?farm=eu2-prod-dca"
    },
    {
      "name": "eu3-trial-dca",
      "location": "Frankfurt",
      "version": "2022.1",
      "url": "https://dca-health.saas.microfocus.com/dashboard/?farm=eu3-trial-dca"
    },
    {
      "name": "eu4-stg-dca",
      "location": "Frankfurt",
      "version": "2022.1",
      "url": "https://dca-health.saas.microfocus.com/dashboard/?farm=eu4-stg-dca"
    },
    {
      "name": "ap5-trial-dca",
      "location": "Sydney",
      "version": "2022.1",
      "url": "https://dca-health.saas.microfocus.com/dashboard/?farm=ap5-trial-dca"
    },
    {
      "name": "us6-prod-reporting",
      "location": "Oregon",
      "version": "2022.1",
      "url": "https://dca-health.saas.microfocus.com/dashboard/?farm=us6-prod-reporting"
    },
    {
      "name": "us8-stg-reporting",
      "location": "Oregon",
      "version": "2022.1",
      "url": "https://dca-health.saas.microfocus.com/dashboard/?farm=us8-stg-reporting"
    }
  ]

}

export {
  APPMODE,
  serviceCollection,
  FARM_ALIAS,
  MODE,
  OPS_DATA_URL,
  MONITOR_DATA_URL,
  userPoolId,
  userPoolWebClientId,
  region,
  mainPageTitle,
  portalPageTitle,
  farmGeoData,
  farmDetails
};


export const brandColor = "linear-gradient(270deg, #088CB2 0%, #146693 8.78%, #23346A 36.9%, #222E61 74.96%, #19224A 100%)";
export const DATE_PICKER_FORMAT = "YYYY-MM-DD HH:mm:ss zZ";